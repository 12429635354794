<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12"><h1>Account Setting</h1></div>
    </div>
    <div class="row">
      <div class="col-md-3 d-none d-md-block" id="menuWrapper">
        <AccountSettingMenu activeMenu="cancel-account" />
      </div>
      <div class="col-md-9">
        <SettingTitle title="Cancel Account" />
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Tell Us Why You're Leaving</h5>
            <hr />
            <div class="card-text">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="reason"
                  id="radios1"
                  value="option1"
                  checked
                />
                <label class="form-check-label" for="radios1"> I have safety concerns. </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="reason"
                  id="radios2"
                  value="option2"
                />
                <label class="form-check-label" for="radios2"> I have privacy concerns.</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="reason"
                  id="radios3"
                  value="option2"
                />
                <label class="form-check-label" for="radios3"> I don't find it useful.</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="reason"
                  id="radios4"
                  value="option2"
                />
                <label class="form-check-label" for="radios4">
                  I don't understand how to use it.
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="reason"
                  id="radios5"
                  value="option2"
                />
                <label class="form-check-label" for="radios5">
                  It's temporary, I'II be back.
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="reason"
                  id="radios6"
                  value="option2"
                />
                <label class="form-check-label" for="radios6"> Other </label>
              </div>
              <div class="mt-3">
                <label for="otherReason" class="form-label">Care to tell us more?</label>
                <textarea class="form-control" id="otherReason" rows="3"></textarea>
              </div>
              <div class="mt-3">
                <h6>Can we contact you for more details?</h6>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    checked
                  />
                  <label class="form-check-label" for="inlineRadio1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                  />
                  <label class="form-check-label" for="inlineRadio2">No</label>
                </div>
              </div>
              <h5 class="card-title">Alternatives To Canceling</h5>
              <hr />
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="alternatives"
                  id="radiosAlternatives1"
                  value="option2"
                  checked
                />
                <label class="form-check-label" for="radiosAlternatives1">
                  Adjust my privacy setting
                </label>
              </div>
              <div class="text-muted">
                Turn off search indexing on your listing, preventing search engines such as Google
                and Bing from displaying your listing in their search results.
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="alternatives"
                  id="radiosAlternatives2"
                  value="option2"
                />
                <label class="form-check-label" for="radiosAlternatives2">
                  Change notification preferences
                </label>
              </div>
              <div class="text-muted">
                Are we sending you too much email? Change your notification preferences.
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="alternatives"
                  id="radiosAlternatives3"
                  value="option2"
                />
                <label class="form-check-label" for="radiosAlternatives3"> Hide my listings </label>
              </div>
              <div class="text-muted">
                Remove your listings from being viewed or found in search to stop hosting on Trepr.
              </div>
              <button class="btn btn-primary">Save settings</button>

              <h5 class="card-title mt-3">What's Going To Happen</h5>
              <hr />
              <div class="text-muted">
                Your profile and any listings will disappear.<br />We'll miss you terribly.
              </div>
              <button class="btn btn-primary">Cancel my account</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingMenu from './Menu';
import SettingTitle from './SettingTitle';
export default {
  name: 'AccountSetting',
  components: { AccountSettingMenu, SettingTitle },
};
</script>

<style scoped></style>
